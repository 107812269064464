import { addDays, isWithinInterval } from 'date-fns';
import { useQuery } from 'react-query';
import { getAuthorizedUser, getUserActivities } from '../service/strava';
import { convertDatetoEpoch } from '../utils';
import { useAppContext } from './useAppContext';

const useGetAuthorizedUser = (code: string) => {
  // we get a full user back, we set the user also in sessionstorage in case the user comes back to site later
  const { setUser } = useAppContext();
  const data = useQuery(['auth-user', code], () => getAuthorizedUser(code), {
    onSuccess: (data) => {
      setUser(data);
      sessionStorage.setItem('stravaUser', JSON.stringify(data));
    }
  });
  return data;
};

const useGetStravaActivities = () => {
  const { user, activities, teams, stages, currentStage } = useAppContext();
  const determineDateRange = () => {
    // we determine the date range based on the main event, if there is a special event ongoing we set this date range so there opens
    // an 'upload window' during this event

    //check if there is any stage that is on right now

    if (currentStage) {
      return {
        start: convertDatetoEpoch(currentStage.start_date),
        end: convertDatetoEpoch(currentStage.end_date)
      };
    }

    // if there is no special event ongoing, and the main event has not started yet, we return unefined

    if (!currentStage && new Date() < stages.tdf[0].start_date) return undefined;

    // when the tour has started, we open the upload window for the whole tour, until 7 days after the event has finished
    return {
      start: convertDatetoEpoch(stages.tdf[0].start_date),
      end: convertDatetoEpoch(addDays(stages.tdf[stages.tdf.length - 1].end_date, 7))
    };
  };

  const data = useQuery(['strava-activities', user?.athlete.id], () => getUserActivities(determineDateRange()), {
    select: (data) =>
      data
        // only select bicicyle rides - strava api does not seem to offer this
        ?.filter((item) => item.sport_type === 'Ride')
        ?.map((item) => {
          return {
            // again since they are already in de firestore
            // we also add the correct team and stage to the activity
            ...item,
            athlete_name: `${user?.athlete.firstname} ${user?.athlete.lastname}`,
            checked: false,
            disabled: !!activities.find((act) => act.id === item.id),
            stage_id: [...stages.tdf, ...stages.specials].find((stage) =>
              isWithinInterval(new Date(item.start_date_local), { start: stage.start_date, end: stage.end_date })
            )?.id,
            team_id: teams.find((team) => team.members.find((member) => member.id === user?.athlete.id))?.id
          };
        })
  });

  return data;
};

export { useGetAuthorizedUser, useGetStravaActivities };
