import { FC, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { RankingWidget } from '../rankingWidget/RankingWidget';
import { useAppContext, useMediaQuery } from '../../hooks';
import { Button } from '../button/Button';
import { useTranslation } from 'react-i18next';
import { ActivityList } from '../activities/activityList/ActivityList';

import styles from './stage.module.css';
import { Text } from '../typography/text/Text';
import clsx from 'clsx';

const Stage: FC = () => {
  const [toggle, setToggle] = useState<boolean>(true);
  const params = useParams();
  const location = useLocation();
  const { activities: stateActivities, teams, currentStage } = useAppContext();
  const { t } = useTranslation();
  const smallScreen = useMediaQuery('(max-width: 1000px)');

  // use the activities that are in the selected stage
  const activities = stateActivities.filter((activity) => activity.stage_id?.toString() === params.stage);
  console.log(activities);

  return (
    <div className={styles.stageWrapper}>
      <div className={styles.stageTabs}>
        <Button onClick={() => setToggle(!toggle)} tab className={toggle ? styles.active : ''}>
          {t('ACTIVITIES.NAME')}
        </Button>
        <Button onClick={() => setToggle(!toggle)} tab className={!toggle ? styles.active : ''}>
          {t('RANKINGS.NAME_PLRL')}
        </Button>
      </div>

      <div className={clsx(styles.wrapper, activities.length === 0 && styles.wrapperNoActivities)}>
        {activities.length === 0 && <Text alignment="center">{t('ACTIVITIES.NO_ACTIVITIES')}</Text>}
        {activities.length > 0 && (
          <>
            {(!smallScreen || (smallScreen && toggle)) && (
              <ActivityList activities={activities} className={styles.left} />
            )}
            {(!smallScreen || (smallScreen && !toggle)) && (
              <RankingWidget
                teams={teams!}
                activities={activities!}
                currentStage={currentStage}
                special={location.pathname.includes('specials')}
                className={styles.left}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { Stage };
