import { FC } from 'react';
import { pictures } from '../../assets/images';

import styles from './caroussel.module.css';
import { motion } from 'framer-motion';

const Caroussel: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.caroussel}>
        {pictures.map((picture, i) => (
          <motion.img
            src={picture}
            alt={`${picture}`}
            className={styles.image}
            key={i}
            initial={{ opacity: 0.5 }}
            whileHover={{ opacity: 1, scale: 1.1, x: 10, zIndex: 1 }}
            transition={{ duration: 0.3 }}
          />
        ))}
      </div>
    </div>
  );
};

export { Caroussel };
