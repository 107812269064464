import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppDataProvider } from './context/AppDataContext';
import { initState } from './reducer/appDataReducer';
import './translations/i18n';

import './styles/globals.css';

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 10000, cacheTime: 10000 } }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppDataProvider {...initState}>
        <App />
      </AppDataProvider>
    </QueryClientProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
