import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../typography/text/Text';
import i18next from 'i18next';

import styles from '../countDown.module.css';
interface DateTimeDisplayProps {
  value: number;
  type: 'days' | 'hours' | 'minutes' | 'seconds';
  size?: 'xl' | 'lg' | 'base';
  direction?: 'row' | 'column';
}

const DateTimeDisplay: FC<DateTimeDisplayProps> = ({ value, type, size, direction }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.dateTimeDisplay}>
      <div className={direction === 'row' ? '' : styles.displayValue}>
        <Text className={styles[size ? size : '']}>{value}</Text>
      </div>
      <Text span>
        {type === 'days' && value === 1 ? (i18next.language === 'nl' ? 'Dag' : 'Day') : t(`TIME.${type.toUpperCase()}`)}
      </Text>
    </div>
  );
};

export { DateTimeDisplay };
