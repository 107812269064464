import { ReactNode } from 'react';
import clsx from 'clsx';

import styles from '../typography.module.css';

interface TextProps {
  size?: 'sm' | 'base' | 'lg';
  alignment?: 'left' | 'center' | 'right' | 'justify';
  capitalize?: boolean;
  bold?: boolean;
  uppercase?: boolean;
  underline?: boolean;
  variant?: 'action';
  className?: string;
  span?: boolean;
  children: ReactNode;
  light?: boolean;
  thin?: boolean;
  winner?: boolean;
}

const Text = ({ alignment, className, children, uppercase, span, variant, thin, size, light, winner }: TextProps) => {
  const TextElement = span ? 'span' : ('p' as keyof JSX.IntrinsicElements);
  return (
    <TextElement
      className={clsx(
        className,
        size && styles[size],
        alignment && styles[alignment],
        variant && styles[variant],
        uppercase && styles.upperCase,
        thin && styles.thin,
        light && styles.light,
        winner && styles.winner
      )}
    >
      {children}
    </TextElement>
  );
};
export { Text };
