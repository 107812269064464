import { FC } from 'react';

import styles from './spinner.module.css';

const Spinner: FC = () => {
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.loadingSpinner}></div>
    </div>
  );
};

export { Spinner };
