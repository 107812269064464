import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Caroussel, CountdownTimer, Header, NextChallenges, RankingWidget, Title } from '../../components';
import { useAppContext, useMediaQuery } from '../../hooks';
import { constructUpcomingEvents } from '../challenges/Challenges';

import styles from './home.module.css';

const Home: FC = () => {
  const {
    activities,
    teams,
    stages: { tdf: stages },
    restDays,
    currentStage
  } = useAppContext();
  const { t } = useTranslation();
  const currentStageActivities = activities.filter((activity) => activity.stage_id === currentStage?.id);

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isRow = useMediaQuery('(max-width: 1300px)');
  const isBiggerScreen = useMediaQuery('(min-width: 1000px)');

  return (
    <>
      <Header />
      <div className={clsx(!isBiggerScreen ? 'container' : '', isMobile && styles.mobileContainer)}>
        <Title variant={1} level={1} alignment={isMobile ? 'left' : 'center'} className={styles.headingTitle}>
          {t('TITLE.FULL')}
        </Title>
        <div className={styles.widgets}>
          <Caroussel />
          <div className={styles.dataWidgets}>
            <div className={styles.dataWidget}>
              {new Date() >= stages[0].start_date ? (
                <RankingWidget teams={teams} activities={currentStageActivities} currentStage={currentStage} />
              ) : (
                <div>
                  <CountdownTimer targetDate={stages[0].start_date} direction={isRow ? 'row' : 'column'} />
                </div>
              )}
              {/* {!isMobile && ( */}
              <Button to={`/stages/${currentStage?.id ?? 1}`} className={styles.homeWidgetBtn}>
                {t('STAGES.NAME_PLRL')}
              </Button>
              {/* )} */}
            </div>
            <div className={styles.dataWidget}>
              <NextChallenges events={constructUpcomingEvents(stages, restDays)} currentDay={new Date()} />
              {/* {!isMobile && ( */}
              <Button to="challenges" className={styles.homeWidgetBtn}>
                {t('CHALLENGES.NAME')}
              </Button>
              {/* )} */}
            </div>
          </div>
          {/* {isMobile && (
            <div className={styles.mobileButtons}>
              <Button to="challenges">{t('CHALLENGES.NAME')}</Button>
              <Button to={`/stages/${currentStage?.id ?? 1}`}>{t('STAGES.NAME_PLRL')}</Button>

              <Button
                to="specials"
                size="small"
                variant="action"
                className={clsx(styles.specialEventMobileBtn, 'specialEventBtn')}
              >
                {t('SPECIAL_EVENTS.NAME')}
              </Button>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export { Home };
