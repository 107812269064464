import { FC, ReactNode } from 'react';
import { useAppContext } from '../../hooks';
import { Text } from '../typography/text/Text';
import { useTranslation } from 'react-i18next';
import { StravaConnect } from '../stravaConnect/StravaConnect';

import styles from './privateRoute.module.css';
interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  if (!user) {
    return (
      <div>
        <div className={styles.login}>
          <Text>{t('MESSAGES.LOGIN_MSG')}</Text>
          <StravaConnect />
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export { PrivateRoute };
