import { FC } from 'react';
import { TActivity, TStage } from '../../../types/types';
import { Title } from '../../typography/title/Title';
import { ActivityCard } from '../ActivityCard';
import clsx from 'clsx';

import styles from './activityCardList.module.css';

interface ListProps {
  activities: TActivity[];
  stages?: TStage[];
  closed?: boolean;
  title?: string;
  subTitle?: string;
  stageInfo?: boolean;
  center?: boolean;
  className?: string;
}

const ActivityList: FC<ListProps> = ({ activities, closed, title, subTitle, stageInfo, center = true, className }) => {
  return (
    <div className={clsx(styles.listWrapper, className)}>
      <div className={styles.listHeading}>
        <Title alignment="center" variant={2} level={3}>
          {title}
        </Title>
        {subTitle && (
          <Title alignment="center" variant={3} level={4}>
            {subTitle}
          </Title>
        )}
      </div>
      <div className={center ? styles.center : ''}>
        <ul className={styles.list}>
          {activities.map((act) => (
            <li key={act.id} className={clsx(styles.listItem, center && styles.centered)}>
              <ActivityCard {...act} closed={closed} stageInfo={stageInfo} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { ActivityList };
