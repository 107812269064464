import { FC } from 'react';
import { TUser } from '../../types/types';
import { FiActivity } from 'react-icons/fi';
import { MdLogout } from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';
import { Text } from '../typography/text/Text';

import styles from './userinfo.module.css';

interface UserInfoProps {
  user: TUser;
  logout: () => void;
}

const UserInfo: FC<UserInfoProps> = ({ user, logout }) => {
  return (
    <ul className={styles.stats}>
      <li>
        <Text alignment="right" className={styles.name}>
          {user?.athlete.firstname + ' ' + user?.athlete.lastname?.charAt(0).toUpperCase() + '.'}
        </Text>
        <AiOutlineUser size={20} />
      </li>
      <li>
        <Text alignment="right">{user?.activities?.length ?? 0}</Text>
        <FiActivity size={20} />
      </li>
      <li>
        <MdLogout size={20} onClick={() => logout()} />
      </li>
    </ul>
  );
};

export { UserInfo };
