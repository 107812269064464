import { FC } from 'react';
import { Title } from '../typography/title/Title';
import { ListItem } from './ListItem';

import styles from './ranking.module.css';

export interface IRanking {
  id: string | number;
  name: string;
  totalMovingTime?: number;
  targetAchieved?: boolean;
  totalDistance?: number;
  totalHeightMeters?: number;
  highestMaxSpeed?: string | number;
  highestAverageSpeed?: string | number;
  totalKudos?: number;
  speed?: number;
}

export type TRanking = { title: string; ranking: IRanking[] };
export interface RankingListProps {
  rankings: TRanking[];
}

const RankingList: FC<RankingListProps> = ({ rankings }) => {
  return (
    <div className={styles.rankingList}>
      {rankings.map(({ ranking, title }, i) => (
        <div key={i}>
          <div className={styles.listItemTitle}>
            <Title variant={3} level={4}>
              {title}
            </Title>
          </div>
          <ul>
            {ranking.map((ranking, i) => (
              <ListItem
                key={i}
                totalDistance={ranking.totalDistance!}
                totalHeightMeters={ranking.totalHeightMeters!}
                id={ranking.id}
                name={ranking.name}
                highestMaxSpeed={ranking.highestMaxSpeed!}
                highestAverageSpeed={ranking.highestAverageSpeed!}
                totalMovingTime={ranking.totalMovingTime!}
                targetAchieved={ranking.targetAchieved!}
                totalKudos={ranking.totalKudos!}
                speed={ranking.speed!}
                index={i}
                className={styles.listItem}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export { RankingList };
