import axios from 'axios';
import { TActivity, TUser } from '../types/types';

const getAuthorizedUser = async (code: string): Promise<TUser | undefined> => {
  try {
    const { data: authenticatedUser } = await axios.post(
      `https://www.strava.com/api/v3/oauth/token?client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&code=${code}&grant_type=authorization_code`
    );
    return authenticatedUser;
  } catch (error) {
    console.error(`Unable to fetch authorized Strava user. ${error}`);
  }
};

const refreshToken = async (): Promise<Partial<TUser> | undefined> => {
  try {
    const stravaUser = JSON.parse(sessionStorage.getItem('stravaUser') ?? 'null');
    console.log('refreshing token...');
    const { data: refreshTokenData } = await axios.post(
      `https://www.strava.com/api/v3/oauth/token?client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&refresh_token=${stravaUser.refresh_token}&grant_type=refresh_token`
    );
    sessionStorage.setItem(
      'stravaUser',
      JSON.stringify({
        ...stravaUser,
        access_token: refreshTokenData.access_token,
        refresh_token: refreshTokenData.refresh_token,
        expires_at: refreshTokenData.expires_at,
        expires_in: refreshTokenData.expires_in
      })
    );
    return refreshTokenData;
  } catch (error) {
    console.log(error);
  }
};

const getUserActivities = async (
  dates: { start: number; end: number } | undefined
): Promise<TActivity[] | undefined> => {
  // if there are no dates = no competition, we do not make the call and return
  if (!dates) return;

  const stravaUser = JSON.parse(sessionStorage.getItem('stravaUser') ?? 'null');

  const dateRangeUrlString = `?after=${dates.start}&before=${dates.end}`;

  // we check if the access token is still valid,
  // if not we refresh it (in case we need to set up more api calls to strava we could put this in an axios interceptor)
  try {
    let token = stravaUser.access_token;

    if (stravaUser !== (null || undefined) && stravaUser.expires_at < Date.now() / 1000) {
      const refreshData = await refreshToken();
      token = refreshData?.access_token;
    }

    const { data: activities } = await axios(`https://www.strava.com/api/v3/athlete/activities${dateRangeUrlString}`, {
      headers: {
        Authorization: `${stravaUser.token_type} ${token}`
      }
    });

    return activities;
  } catch (error) {
    console.error(`Unable to fetch Strava activities. ${error}`);
  }
};

export { getAuthorizedUser, getUserActivities };
