import { addDays, isWithinInterval } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarEvent, CalendarEventProps } from '../calendar/calendarEvent/CalendarEvent';
import { Title } from '../typography/title/Title';

import styles from './nextChallenges.module.css';

interface NextChallengesProps {
  events: CalendarEventProps[];
  currentDay: Date;
}

const NextChallenges: FC<NextChallengesProps> = ({ events, currentDay }) => {
  const { t } = useTranslation();
  const currentStageIndex = events.findIndex((event) =>
    isWithinInterval(currentDay, {
      start: event.startDate,
      end: addDays(event.endDate, 1)
    })
  );

  const next2 = events.slice(currentStageIndex + 1, currentStageIndex + 3);

  return (
    <div className={styles.nextChallenges}>
      <Title level={2} variant={2}>
        {t('CHALLENGES.UPCOMING_CHALLENGES')}
      </Title>
      {next2.map((event) => (
        <CalendarEvent key={event.startDate.toDateString()} {...event} />
      ))}
    </div>
  );
};

export { NextChallenges };
