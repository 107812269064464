import { useFirestoreCollectionMutation } from '@react-query-firebase/firestore';
import { collection } from 'firebase/firestore';
import { db } from '../firebase';
import { TActivity } from '../types/types';
import { useQueryClient } from 'react-query';
import { useAppContext } from './useAppContext';

const useAddActivities = () => {
  const { user } = useAppContext();
  const queryClient = useQueryClient();
  const mutation = useFirestoreCollectionMutation(collection(db, `tdi2023/activities/activities`), {
    onSuccess: () => {
      queryClient.invalidateQueries('activities');
    }
  });

  const uploadActivities = (activities: TActivity[]) => {
    // we remove the checked property that we used for the checkboxes, we include the team_id of the user
    try {
      console.log('upload', activities);
      activities.map(async (activity) => {
        const { checked, ...rest } = activity;
        mutation.mutate({ ...rest, team_id: user?.team?.id });
      });
      console.log('unique activities posted in the db!');
    } catch (err) {
      console.log(err);
    }
  };

  return { uploadActivities, mutation };
};

export { useAddActivities };
