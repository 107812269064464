import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { collection, orderBy, query } from 'firebase/firestore';
import { db, activityConverter, restDayConverter, stageConverter, teamConverter } from '../firebase';
import { TActivity, TRestDay, TStage, TTeam } from '../types/types';
import { useAppContext } from './useAppContext';

/// get activities per stage
const useGetActivities = () => {
  const { setActivities } = useAppContext();
  const ref = query(collection(db, `tdi2023/activities/activities`), orderBy('start_date_local', 'desc'));
  const data = useFirestoreQueryData<TActivity>(
    [`activities`],
    ref.withConverter<TActivity>(activityConverter),
    { subscribe: true },
    {
      onSettled: (data) => {
        if (data) {
          setActivities(data);
        }
      }
    }
  );

  return data ?? [];
};

/// rest days
const useGetRestDays = () => {
  const { setRestDays } = useAppContext();
  const ref = collection(db, `tdi2023/rustdagen/rustdagen`).withConverter<TRestDay>(restDayConverter);
  const data = useFirestoreQueryData<TRestDay>(
    ['rustdagen'],
    ref,
    { subscribe: false },
    {
      onSettled: (data) => {
        if (data) setRestDays(data);
      }
    }
  );
  return data;
};

/// stages
const useGetStages = () => {
  const { setStages } = useAppContext();
  const ref = collection(db, `tdi2023/stages/stages`).withConverter<TStage>(stageConverter);
  const data = useFirestoreQueryData<TStage>(
    ['stages'],
    ref,
    { subscribe: false },
    {
      onSettled: (data) => {
        if (data) setStages(data);
      }
    }
  );

  return data;
};

/// teams
const useGetTeams = () => {
  const { setTeams } = useAppContext();
  const ref = collection(db, `tdi2023/teams/teams`).withConverter<TTeam>(teamConverter);
  const data = useFirestoreQueryData<TTeam>(
    ['teams'],
    ref,
    { subscribe: false },
    {
      onSettled: (data) => {
        if (data) {
          setTeams(data);
        }
      }
    }
  );
  return data;
};

/// stages
const useGetSpecialEvents = () => {
  const { setSpecialEvents } = useAppContext();
  const ref = collection(db, `tdi2023/specialEvents/specialEvents`).withConverter<TStage>(stageConverter);
  const data = useFirestoreQueryData<TStage>(
    ['specialEvents'],
    ref,
    { subscribe: false },
    {
      onSettled: (data) => {
        if (data) {
          setSpecialEvents(data);
        }
      }
    }
  );

  return data;
};

export { useGetActivities, useGetTeams, useGetStages, useGetRestDays, useGetSpecialEvents };
