import { TUser, TActivity, TRestDay, TStage, TTeam, TInitState, REDUCER_ACTION_TYPE } from '../types/types';
import { getCurrentStage } from '../utils';

export const initState: TInitState = {
  stravaUser: undefined,
  teams: [],
  stages: [],
  restDays: [],
  activities: [],
  specialEvents: [],
  currentStage: undefined
};

export type TReducerAction =
  | {
      type: REDUCER_ACTION_TYPE.SET_USER_DATA;
      payload: TUser;
    }
  | { type: REDUCER_ACTION_TYPE.SET_RESTDAYS; payload: TRestDay[] }
  | { type: REDUCER_ACTION_TYPE.SET_TEAMS; payload: TTeam[] }
  | { type: REDUCER_ACTION_TYPE.SET_STAGES; payload: TStage[] }
  | { type: REDUCER_ACTION_TYPE.SET_ACTIVITIES; payload: TActivity[] }
  | { type: REDUCER_ACTION_TYPE.ADD_ACTIVITIES; payload: TActivity[] }
  | { type: REDUCER_ACTION_TYPE.SET_SPECIAL_EVENTS; payload: TStage[] }
  | { type: REDUCER_ACTION_TYPE.TOGGLE_ACTIVITY_TO_UPLOAD; payload: TActivity }
  | { type: REDUCER_ACTION_TYPE.CLEAR_ACTIVITIES_TO_UPLOAD }
  | { type: REDUCER_ACTION_TYPE.ADD_ALL_ACTIVITIES_TO_UPLOAD; payload: TActivity[] };

export const dataReducer = (state: TInitState, action: TReducerAction): TInitState => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.SET_USER_DATA:
      return {
        ...state,
        stravaUser:
          action.payload !== undefined
            ? {
                ...action.payload,
                activities: state.activities.filter((act) => act.athlete.id === action.payload?.athlete.id),
                team: state.teams.find((team) =>
                  team.members.find((member) => member.id === action.payload?.athlete.id)
                )
              }
            : undefined
      };

    case REDUCER_ACTION_TYPE.SET_RESTDAYS:
      return { ...state, restDays: action.payload };

    case REDUCER_ACTION_TYPE.SET_TEAMS:
      return { ...state, teams: action.payload };

    case REDUCER_ACTION_TYPE.SET_STAGES:
      return {
        ...state,
        stages: action.payload,
        currentStage: getCurrentStage(action.payload)
      };

    case REDUCER_ACTION_TYPE.SET_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
        stravaUser: state.stravaUser
          ? {
              ...state.stravaUser,
              activities: action.payload.filter((act) => act.athlete.id === state.stravaUser?.athlete.id)
            }
          : undefined
      };

    case REDUCER_ACTION_TYPE.ADD_ACTIVITIES:
      const data = {
        ...state,
        activities: [...state.activities, ...action.payload]
      };
      return data;

    default:
      return state;
  }
};
