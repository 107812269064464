import { createContext, ReactElement, useReducer } from 'react';
import { dataReducer, initState, TReducerAction } from '../reducer/appDataReducer';
import { TInitState } from '../types/types';

const initContextState = {
  state: initState,
  dispatch: (action: TReducerAction) => {}
};

const AppDataContext = createContext<typeof initContextState>(initContextState);

type ChildrenType = {
  children: ReactElement | undefined;
};

const AppDataProvider = ({ children, ...initState }: ChildrenType & TInitState): ReactElement => {
  const [state, dispatch] = useReducer(dataReducer, initState);

  return <AppDataContext.Provider value={{ state, dispatch }}>{children}</AppDataContext.Provider>;
};

export { AppDataContext, AppDataProvider };
