import { isWithinInterval } from 'date-fns';
import { TActivity, TStage } from '../types/types';

// format the header title
const headerTitleFormatter = (val: string) => {
  const title = val.slice(1).charAt(0).toUpperCase() + val.slice(2);
  return title.includes('/') ? title.slice(0, title.indexOf('/')) : title;
};

// get the current Stage
const getCurrentStage = (stages: TStage[]) => {
  return stages.find((stage) =>
    isWithinInterval(new Date(), {
      start: new Date(stage.start_date),
      end: new Date(stage.end_date)
    })
  );
};

// convert date to epoch for api calls
const convertDatetoEpoch = (date: Date) => date.getTime() / 1000.0;

// determine the checked activities in the uploadzone
const checkedActivities = (activities: TActivity[]): TActivity[] => {
  const filteredActivities = activities.filter((activity) => activity.checked === true);
  return filteredActivities;
};

//shorten surname
const shortenName = (name: string) => {
  // split name on " ", use first name completely, use 1st letters of last name(s)
  const splitted = name.split(' ');
  const firstName = splitted[0];
  const lastNames = splitted.slice(1);
  const onlyFirstChar = lastNames.map((name) => name.charAt(0) + '.');
  return `${firstName + ' ' + onlyFirstChar.join(' ')}`;
};

export { headerTitleFormatter, checkedActivities, getCurrentStage, shortenName, convertDatetoEpoch };
