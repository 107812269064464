import { useContext, useEffect } from 'react';
import { AppDataContext } from '../context/AppDataContext';
import { REDUCER_ACTION_TYPE, TActivity, TRestDay, TStage, TTeam, TUseAppDataHook, TUser } from '../types/types';
import { getCurrentStage } from '../utils';

const useAppContext = (): TUseAppDataHook => {
  const { state, dispatch } = useContext(AppDataContext);

  useEffect(() => {
    const athleteFromSession = JSON.parse(sessionStorage.getItem('stravaUser') ?? 'null') as TUser | null;
    if (athleteFromSession !== null) {
      dispatch({ type: REDUCER_ACTION_TYPE.SET_USER_DATA, payload: athleteFromSession });
    }
    if (athleteFromSession === null) {
      return;
    }
    return;
  }, [dispatch]);

  const logout = () => {
    sessionStorage.clear();
    dispatch({ type: REDUCER_ACTION_TYPE.SET_USER_DATA, payload: undefined });
  };

  const login = (redirectPath: string) => {
    sessionStorage.setItem('redirect', redirectPath);
    window.location.href = process.env.REACT_APP_STRAVA_AUTHORIZE_URL as string;
  };

  return {
    user: state.stravaUser,
    activities: state.activities,
    stages: { tdf: state.stages, specials: state.specialEvents },
    teams: state.teams,
    restDays: state.restDays,
    currentStage: getCurrentStage([...state.stages, ...state.specialEvents]),
    setActivities: (activities: TActivity[]) =>
      dispatch({ type: REDUCER_ACTION_TYPE.SET_ACTIVITIES, payload: activities }),
    setSpecialEvents: (specialEvents: TStage[]) =>
      dispatch({ type: REDUCER_ACTION_TYPE.SET_SPECIAL_EVENTS, payload: specialEvents }),
    setStages: (stages: TStage[]) => dispatch({ type: REDUCER_ACTION_TYPE.SET_STAGES, payload: stages }),
    setUser: (user: TUser) => dispatch({ type: REDUCER_ACTION_TYPE.SET_USER_DATA, payload: user }),
    setTeams: (teams: TTeam[]) => dispatch({ type: REDUCER_ACTION_TYPE.SET_TEAMS, payload: teams }),
    setRestDays: (restDays: TRestDay[]) => dispatch({ type: REDUCER_ACTION_TYPE.SET_RESTDAYS, payload: restDays }),
    addActivities: (activities: TActivity[]) =>
      dispatch({ type: REDUCER_ACTION_TYPE.ADD_ACTIVITIES, payload: activities }),
    logout,
    login
  };
};

export { useAppContext };
