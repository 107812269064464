import clsx from 'clsx';
import { FC } from 'react';
import { DateTimeDisplay } from '../dateTimeDisplay/DateTimeDisplay';

import styles from '../countDown.module.css';

interface ShowCounterProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  direction?: 'row' | 'column';
}

const ShowCounter: FC<ShowCounterProps> = ({ days, hours, minutes, seconds, direction }) => {
  return (
    <div className={clsx(styles.showCounter, direction === 'row' ? styles.row : styles.column)}>
      <DateTimeDisplay value={days} type="days" size="xl" direction="row" />
      <DateTimeDisplay value={hours} type="hours" size="lg" direction="row" />
      <DateTimeDisplay value={minutes} type="minutes" size="base" direction="row" />
      <DateTimeDisplay value={seconds} type="seconds" direction="row" />
    </div>
  );
};

export { ShowCounter };
