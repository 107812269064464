import { FC } from 'react';
import { addDays, format, startOfWeek } from 'date-fns';
import { CalendarEvent, CalendarEventProps } from './calendarEvent/CalendarEvent';
import { Text } from '../typography/text/Text';
import { useTranslation } from 'react-i18next';

import styles from './calendar.module.css';

interface CalendarProps {
  events: CalendarEventProps[];
}

export const Calendar: FC<CalendarProps> = ({ events }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.calendar}>
      {Array.from(Array(7).keys()).map((day) => (
        <Text size="lg" key={day} className={styles.header}>
          {t(`WEEKDAYS.${format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), day), 'eeee').toUpperCase()}`)}
        </Text>
      ))}
      {events?.map((event) => (
        <CalendarEvent key={event.startDate.toDateString()} {...event} />
      ))}
    </div>
  );
};
