import { FC } from 'react';
import { Navigate } from 'react-router';
import { ErrorRedirect, Spinner } from '../../components';
import { useGetAuthorizedUser } from '../../hooks';

const StravaRedirect: FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const { isLoading, error, isFetched } = useGetAuthorizedUser(searchParams.get('code')!);

  const redirectPage = sessionStorage.getItem('redirect') as string;

  return (
    <>
      {isFetched && <Navigate to={redirectPage} />}
      {isLoading && <Spinner />}
      {error && <ErrorRedirect />}
    </>
  );
};

export { StravaRedirect };
