import { useReducer } from 'react';
import { initUploadState, uploadReducer } from '../reducer/uploadReducer';
import { REDUCER_ACTION_TYPE, TActivity } from '../types/types';

type TUseUploadReducer = {
  selectedActivities: TActivity[];
  toggle: (activity: TActivity) => void;
  selectAll: (activities: TActivity[]) => void;
  clearAll: () => void;
};

export const useUploadReducer = (): TUseUploadReducer => {
  const [state, dispatch] = useReducer(uploadReducer, initUploadState);

  const toggle = (activity: TActivity) =>
    dispatch({ type: REDUCER_ACTION_TYPE.TOGGLE_ACTIVITY_TO_UPLOAD, payload: activity });

  const selectAll = (activities: TActivity[]) =>
    dispatch({ type: REDUCER_ACTION_TYPE.ADD_ALL_ACTIVITIES_TO_UPLOAD, payload: activities });

  const clearAll = () => dispatch({ type: REDUCER_ACTION_TYPE.CLEAR_ACTIVITIES_TO_UPLOAD });

  return { selectedActivities: state?.activities, toggle, selectAll, clearAll };
};
