import { FC } from 'react';
import { Text } from '../../typography/text/Text';
import { Title } from '../../typography/title/Title';

import styles from '../error.module.css';

interface NoDataProps {
  title: string;
  text: string;
}
const NoData: FC<NoDataProps> = ({ title, text }) => {
  return (
    <div className={styles.errorContainer}>
      <Title variant={2} level={3}>
        {title}
      </Title>
      <Text alignment="center">{text}</Text>
    </div>
  );
};

export { NoData };
