import { FC } from 'react';
import { TActivity, TStage, TTeam } from '../../types/types';
import { RankingList, TRanking } from './RankingList';
import { Title } from '../typography/title/Title';
import { Text } from '../typography/text/Text';
import { useTranslation } from 'react-i18next';
import { useAppContext, useCalculations } from '../../hooks';
import { useParams } from 'react-router';

import styles from './ranking.module.css';

interface RankingProps {
  className?: string;
  activities: TActivity[];
  teams: TTeam[];
  currentStage?: TStage;
  special?: boolean;
}

const RankingWidget: FC<RankingProps> = ({ activities, teams, currentStage, special }) => {
  const { stages } = useAppContext();
  const calcs = useCalculations();
  const { t } = useTranslation();
  const { stage } = useParams();

  const stageData = stage ? [...stages.tdf, ...stages.specials].find((s) => s.id === stage) : currentStage;

  let rankingList: TRanking[];
  switch (stageData?.id) {
    case '1':
      rankingList = calcs.stage1(teams, activities);
      break;
    case '2':
      rankingList = calcs.stage2or5(teams, activities);
      break;
    case '3':
      rankingList = calcs.stage3(teams, activities);
      break;
    case '4':
      rankingList = [];
      break;
    case '5':
      rankingList = calcs.stage2or5(teams, activities);
      break;
    case '6':
      rankingList = calcs.stage6(activities);
      break;
    case '7':
      rankingList = calcs.stage7(teams, activities);
      break;
    case '8':
      rankingList = calcs.stage8(teams, activities);
      break;
    case '9':
      rankingList = [];
      break;
    ////////////////////////////////////////////////////
    // TODO: this logic is not optimal since we need to adjust it here when something changes in the db. find a solution
    case 'testEvent':
      rankingList = calcs.specialStage(activities);
      break;
    ////////////////////////////////////////////////////
    default:
      rankingList = [];
  }

  return (
    <div className={styles.rankingWidgetWrapper}>
      <div className={styles.rankingHeader}>
        <Title variant={2} level={3}>
          {stageData && `${stageData.name.nl}`}
        </Title>
        {currentStage && (
          <Title variant={3} level={4} thin>
            {t('RANKINGS.TARGET')}: {currentStage?.distance / 1000} {t('UNITS.KILOMETERS')}{' '}
            {currentStage.target_height && `en ${currentStage?.target_height} ${t('UNITS.HEIGHT_METERS')}`}
          </Title>
        )}
      </div>

      {activities.length > 0 && rankingList.length > 0 ? (
        <RankingList rankings={rankingList} />
      ) : (
        <Text>{t('RANKINGS.NO_RANKINGS')}</Text>
      )}
    </div>
  );
};

export { RankingWidget };
