import clsx from 'clsx';
import { format, intervalToDuration, parseISO } from 'date-fns';
import { FC, useState } from 'react';
import { TActivity } from '../../types/types';
import { Modal } from '../modal/Modal';
import { Text } from '../typography/text/Text';
import { Title } from '../typography/title/Title';
import { IoMapOutline, IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { ActivityMap } from './activityMap/ActivityMap';
import { useMediaQuery } from '../../hooks';
import { shortenName } from '../../utils';
import { useTranslation } from 'react-i18next';

import styles from './activityCard.module.css';

interface ActivityCardProps extends Partial<TActivity> {
  className?: string;
  athleteName?: string;
  disabled?: boolean;
  small?: boolean;
  closed?: boolean;
  stageInfo?: boolean;
}

const ActivityCard: FC<ActivityCardProps> = ({
  className,
  stageInfo,
  athleteName,
  disabled,
  small,
  closed,
  map,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(disabled || closed ? false : true);
  const [mapOpen, setMapOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width: 500px)');
  const { t } = useTranslation();

  return (
    <>
      <div className={clsx(styles.cardWrapper, disabled && styles.disabled, className)}>
        <div className={styles.cardActivity}>
          <div className={clsx(styles.cardHeading, !open && styles.cardHeadingClosed)}>
            <div className={styles.cardTitle}>
              <Title variant={small || isMobile ? 3 : 2} level={3} className={isMobile ? 'mobile' : ''}>
                {props.athlete_name ? shortenName(props.athlete_name) : shortenName(athleteName ?? '')}
              </Title>
              <Text thin className={small || isMobile ? styles.mobile : ''}>
                {props.name}
              </Text>
            </div>

            <div className={styles.dateMaps}>
              <Text>{format(parseISO(props.start_date_local ?? ''), 'dd/MM/yyyy')}</Text>
              <IoMapOutline onClick={() => setMapOpen(true)} className="icon" />
              {open ? (
                <IoChevronUpOutline className="icon" onClick={() => setOpen(false)} />
              ) : (
                <IoChevronDownOutline className="icon" onClick={() => setOpen(true)} />
              )}
            </div>
          </div>
          <div className={clsx(styles.cardContent, !open && styles.hidden)}>
            <Text thin>
              {t('CARD.DISTANCE')}:
              <Text span>
                {props.distance && (props.distance / 1000).toFixed(2)} {t('UNITS.KILOMETERS')}
              </Text>
            </Text>
            <Text thin>
              {t('CARD.MOVING_TIME')}:
              <Text span>
                {`${
                  intervalToDuration({
                    start: 0,
                    end: props?.moving_time! * 1000
                  }).hours
                } ${t('UNITS.HOURS')} ${
                  intervalToDuration({
                    start: 0,
                    end: props.moving_time! * 1000
                  }).minutes
                } ${t('UNITS.MINUTES')}`}
              </Text>
            </Text>

            <Text thin>
              {t('CARD.ELEVATION')}:
              <Text span>
                {props.total_elevation_gain && props.total_elevation_gain.toFixed(2)} {''}
                {t('UNITS.METERS')}
              </Text>
            </Text>

            <Text thin>
              {t('CARD.SPEED')}:
              <Text span alignment="right">
                <Text span>
                  {props.average_speed && (props.average_speed * 3.6).toFixed(2)} {t('UNITS.KM/H')} Avg.
                </Text>
                <Text span>
                  {props.max_speed && (props.max_speed * 3.6).toFixed(2)} {t('UNITS.KM/H')} Max.
                </Text>
              </Text>
            </Text>
          </div>
        </div>

        {stageInfo && (
          <div className={styles.cardStage}>
            <Text alignment="center" thin>
              {props.stage_id}
            </Text>
          </div>
        )}
      </div>
      <Modal setShowModal={setMapOpen} showModal={mapOpen}>
        <ActivityMap polylineString={map?.summary_polyline} />
      </Modal>
    </>
  );
};

export { ActivityCard };
