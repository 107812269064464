import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { Title } from '../typography/title/Title';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './modal.module.css';

interface ModalProps {
  setShowModal: (showModal: boolean) => void;
  showModal: boolean;
  title?: string;
  children: ReactNode;
}

const overlay = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
};

const modal = {
  hidden: {
    y: '-100vh',
    opacity: 0
  },

  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.3
    }
  }
};

const Modal: FC<ModalProps> = ({ showModal, setShowModal, title, children }) => {
  return (
    <AnimatePresence mode="wait">
      {showModal && (
        <motion.div
          className={styles.overlay}
          variants={overlay}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={() => setShowModal(false)}
        >
          <motion.div onClick={(e) => e.stopPropagation()} className={clsx(styles.modalContainer)} variants={modal}>
            {title && (
              <Title level={4} variant={3} className={styles.title}>
                {title}
              </Title>
            )}
            <>{children}</>
            <AiOutlineCloseCircle size="2rem" className={styles.close} onClick={() => setShowModal(false)} />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export { Modal };
