import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Text } from '../../typography/text/Text';

import styles from '../error.module.css';

const ErrorRedirect: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 3000);
  }, [navigate]);

  return (
    <div className={clsx(styles.errorContainer, styles.fullScreen)}>
      <Text>{t('MESSAGES.PAGE_ERR_MSG')}</Text>
    </div>
  );
};

export { ErrorRedirect };
