import { FC } from 'react';
import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import polyline from '@mapbox/polyline';
import { Text } from '../../typography/text/Text';

import styles from './activityMap.module.css';

interface ActivityMapProps {
  polylineString?: string;
}

const ActivityMap: FC<ActivityMapProps> = ({ polylineString }) => {
  const positions = polyline.decode(polylineString ?? '');

  if (positions.length === 0) return <Text alignment="center">Geen map beschikbaar voor deze activiteit</Text>;

  return (
    <MapContainer center={getCenter(positions)} zoom={10} scrollWheelZoom={true} className={styles.mapContainer}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Polyline positions={positions}></Polyline>
    </MapContainer>
  );
};

export { ActivityMap };

const getCenter = (arr: [number, number][]) => {
  return arr.reduce(
    (x, y) => {
      return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
    },
    [0, 0]
  );
};
