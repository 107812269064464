import { FC, useState } from 'react';
import { useAddActivities, useAppContext, useGetStravaActivities, useMediaQuery, useUploadReducer } from '../../hooks';
import { ActivityCheckList, ActivityList, Button, NoData, Spinner, Text, Modal } from '../../components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

import styles from './uploadzone.module.css';

const UploadZone: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const { data: stravaActivities, isLoading } = useGetStravaActivities();
  const { uploadActivities, mutation } = useAddActivities();
  const { user, stages } = useAppContext();
  const { selectedActivities, toggle, selectAll, clearAll } = useUploadReducer();
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery('(min-width: 1000px)');

  const activitiesToUpload = selectedActivities.length > 0;
  console.log(!isLargeScreen && selectedActivities.length > 0);
  if (isLoading) return <Spinner />;

  if (stravaActivities && stravaActivities.length !== 0) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <ActivityCheckList
            stravaActivities={stravaActivities}
            selectedActivities={selectedActivities}
            toggle={toggle}
            selectAll={selectAll}
            clearAll={clearAll}
          />
        </div>

        <div className={styles.right}>
          {!isLargeScreen && selectedActivities.length > 0 && (
            <div className={styles.chevrons}>
              {open ? (
                <IoChevronDownOutline className={styles.chevronIcon} onClick={() => setOpen(false)} />
              ) : (
                <IoChevronUpOutline className={styles.chevronIcon} onClick={() => setOpen(true)} />
              )}
            </div>
          )}
          {open && (
            <ul className={clsx(styles.list, open && !isLargeScreen && styles.hidden)}>
              {selectedActivities.map((activity) => (
                <li key={activity.id}>
                  <Text thin>
                    {activity.name} - <Text span>{format(parseISO(activity.start_date_local), 'dd/MM/yyyy')}</Text>
                  </Text>
                </li>
              ))}
            </ul>
          )}

          <Button
            variant="upload"
            size="large"
            onClick={() => setShowModal(true)}
            disabled={selectedActivities.length === 0 || mutation.isLoading}
          >
            <Text>{t('UPLOAD.NAME')}</Text>
          </Button>
        </div>

        <Modal
          setShowModal={setShowModal}
          showModal={showModal}
          title={activitiesToUpload ? `${t('UPLOAD.SELECTED_ACTIVITIES')}` : ''}
        >
          <ActivityList activities={selectedActivities} closed stageInfo />
          <Button
            variant={activitiesToUpload ? 'upload' : 'standard'}
            onClick={() => {
              if (activitiesToUpload) {
                uploadActivities(selectedActivities);
                clearAll();
                return;
              }
              setShowModal(false);
            }}
          >
            {activitiesToUpload ? t('UPLOAD.NAME') : 'close'}
          </Button>
        </Modal>
      </div>
    );
  }

  return (
    <div className="container">
      <NoData
        title={user ? `Hi ${user.athlete.username?.split('_')[0]} ! ` : t('ACTIVITIES.NO_ACTIVITIES')}
        text={t('MESSAGES.NO_ACTIVITIES_WITHIN_CONTEST')}
      />
      {user?.activities && user.activities.length > 0 && (
        <ActivityList
          className={styles.activityList}
          activities={user.activities}
          stages={[...stages.tdf, ...stages.specials]}
          closed
          title="Reeds geuploade activiteiten"
          center
        />
      )}
    </div>
  );
};

export { UploadZone };
