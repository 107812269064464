import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../hooks';
import { TActivity } from '../../../types/types';
import { ActivityCard } from '../../activities/ActivityCard';
import { Checkbox } from '../../checkbox/Checkbox';
import { Text } from '../../typography/text/Text';

import styles from './activitychecklist.module.css';

interface ActivityCheckListProps {
  stravaActivities: TActivity[];
  selectedActivities: TActivity[];
  selectAll: (activities: TActivity[]) => void;
  clearAll: () => void;
  toggle: (activity: TActivity) => void;
}

const ActivityCheckList: FC<ActivityCheckListProps> = ({
  stravaActivities,
  selectedActivities,
  selectAll,
  clearAll,
  toggle
}) => {
  const { activities: stateActivities } = useAppContext();
  const { t } = useTranslation();

  const freshActivities = stravaActivities.filter(
    (activity) => !stateActivities.map((act) => act.id).includes(activity.id)
  );

  const activityAlreadyInDb = () => {
    const stravaIds = stravaActivities.map((act) => act.id);
    const stateIds = stateActivities.map((act) => act.id);
    return stravaIds.every((id) => stateIds.includes(id));
  };

  return (
    <div className={styles.listWrapper}>
      {activityAlreadyInDb() ? (
        <Text>{t('MESSAGES.ACTIVITIES_ALREADY_UPLOADED')}</Text>
      ) : (
        <Checkbox
          handleCheck={() => (selectedActivities.length === 0 ? selectAll(freshActivities) : clearAll())}
          checked={selectedActivities.length === freshActivities.length}
          indeterminate={selectedActivities.length > 0 && selectedActivities.length < freshActivities.length}
          label={selectedActivities.length !== 0 ? (t('SELECT.NONE') as string) : (t('SELECT.ALL') as string)}
        />
      )}

      <ul className={styles.activitiesList}>
        {stravaActivities.map((activity) => (
          <li key={activity.id} className={styles.activitiesContainer}>
            {
              <Checkbox
                id={activity.id}
                checked={selectedActivities.includes(activity)}
                handleCheck={() => {
                  toggle(activity);
                }}
                disabled={stateActivities.map((act) => act.id).includes(activity.id)}
              />
            }
            <ActivityCard {...activity} stageInfo />
          </li>
        ))}
      </ul>
    </div>
  );
};

export { ActivityCheckList };
