import clsx from 'clsx';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

import styles from './button.module.css';

type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  className?: string;
  variant?: 'action' | 'tab' | 'standard' | 'upload';
  size?: 'small' | 'medium' | 'large';
  to?: To;
  outlined?: boolean;
  children: ReactNode;
  disabled?: boolean;
  tab?: boolean;
  onClick?: () => void;
  mobile?: boolean;
};

const Button: FC<ButtonProps> = ({
  className,
  to,
  variant,
  size,
  children,
  onClick,
  disabled,
  outlined,
  tab,
  mobile
}) => {
  if (to) {
    return (
      <Link
        className={clsx(
          styles.button,
          disabled && styles.disabled,
          size && styles[size],
          outlined && styles.outlined,
          variant && styles[variant],
          tab && styles.tab,
          mobile && styles.mobile,
          className
        )}
        to={disabled ? '' : to}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={clsx(styles.button, size && styles[size], variant && styles[variant], tab && styles.tab, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export { Button };
