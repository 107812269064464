import clsx from 'clsx';
import { FC } from 'react';

import styles from './checkbox.module.css';

interface CheckboxProps {
  id?: number;
  handleCheck: () => void;
  checked?: boolean;
  label?: string;
  value?: number;
  indeterminate?: boolean;
  disabled?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({ handleCheck, id, label, checked, indeterminate = false, disabled }) => {
  return (
    <div className={clsx(styles.checkboxContainer, !id && styles.mainCheck)}>
      <input
        type="checkbox"
        onChange={handleCheck}
        id={id ? `check-${id}` : 'check-main'}
        className={clsx(indeterminate ? styles.indeterminateCheckbox : styles.checkbox, disabled && styles.disabled)}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={id ? `check-${id}` : 'check-main'}>{label}</label>
    </div>
  );
};

export { Checkbox };
