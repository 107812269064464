import {
  FirestoreDataConverter,
  WithFieldValue,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';
import { TActivity, TRestDay, TStage, TTeam } from '../types/types';

/* The firebase converters give the data correct typing, firebase always stores it as DocumentData, therefore we need to convert it */

const activityConverter: FirestoreDataConverter<TActivity> = {
  toFirestore(activity: WithFieldValue<TActivity>): DocumentData {
    return activity;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<TActivity>, options: SnapshotOptions): TActivity {
    const data = snapshot.data(options)!;
    return data;
  }
};

const stageConverter: FirestoreDataConverter<TStage> = {
  toFirestore(stage: WithFieldValue<TStage>): DocumentData {
    return stage;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<any>, options: SnapshotOptions): TStage {
    const data = snapshot.data(options)!;
    return {
      ...data,
      ...(data.tdf_stages != null && {
        tdf_stages: Object.values(data?.tdf_stages)?.map((stage: any) => ({
          ...stage,
          date: new Date(stage?.date?.seconds * 1000)
        }))
      }),
      ...(data.start_date != null && {
        start_date: new Date(data?.start_date?.seconds * 1000)
      }),
      ...(data.end_date != null && {
        end_date: new Date(data?.end_date?.seconds * 1000)
      })
    };
  }
};

const restDayConverter: FirestoreDataConverter<TRestDay> = {
  toFirestore(restDay: WithFieldValue<TRestDay>): DocumentData {
    return { ...restDay };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<any>, options: SnapshotOptions): TRestDay {
    const data = snapshot.data(options)!;
    return {
      start_date: new Date(data?.start_date.seconds * 1000),
      end_date: new Date(data?.end_date.seconds * 1000)
    };
  }
};

const teamConverter: FirestoreDataConverter<TTeam> = {
  toFirestore(restDay: WithFieldValue<TTeam>): DocumentData {
    return { ...restDay };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<TTeam>, options: SnapshotOptions): TTeam {
    const data = snapshot.data(options)!;
    return data;
  }
};

export { stageConverter, activityConverter, restDayConverter, teamConverter };
