import { NavLink } from 'react-router-dom';
import { TStage } from '../../types/types';
import { FC } from 'react';
import { Text } from '../typography/text/Text';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Title } from '../typography/title/Title';

import styles from './stagenav.module.css';

interface StageNavProps {
  stages: TStage[];
  specialStage?: TStage;
  currentDay: Date;
  specials?: boolean;
}

const StageNav: FC<StageNavProps> = ({ stages, currentDay, specials }) => {
  const { t } = useTranslation();

  return (
    <nav className={styles.stageNav}>
      {!specials && (
        <Title variant={3} level={3} className={styles.navHeader}>
          {t('STAGES.NAME_PLRL')}
        </Title>
      )}

      <ul className={clsx(styles.stages, specials && styles.specialEvents)}>
        {stages?.map((stage) => (
          <li key={stage.id}>
            <NavLink to={`${stage.id}`} className={({ isActive }) => (isActive ? styles.active : styles.normal)}>
              <Text span className={clsx(styles.linkContent, specials && styles.linkContentSpecials)}>
                {specials ? `${stage.name.nl}` : `${stage.id}`}
              </Text>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export { StageNav };
