import { ReactElement } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider
} from 'react-router-dom';
import { ErrorRedirect, Layout, PrivateRoute, Root } from './components';
import { Stage } from './components/stage/Stage';
import { UploadZone, Home, StravaRedirect, Challenges, Stages, Specials } from './pages';

const App = (): ReactElement => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />} errorElement={<ErrorRedirect />}>
        <Route index element={<Home />} />
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route
            path="uploadzone"
            element={
              <PrivateRoute>
                <UploadZone />
              </PrivateRoute>
            }
          />

          <Route path="stages" element={<Stages />}>
            <Route index element={<Stage />} />
            <Route path=":stage" element={<Stage />} />
          </Route>

          <Route path="specials" element={<Specials />}>
            <Route index element={<Stage />} />
            <Route path=":stage" element={<Stage />} />
          </Route>

          <Route path="challenges" element={<Challenges />} />

          <Route path="redirect/*" element={<StravaRedirect />} errorElement={<ErrorRedirect />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default App;
