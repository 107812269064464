import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from '../typography.module.css';

interface TitleProps {
  className?: string;
  alignment?: 'left' | 'center' | 'right' | 'justify';
  variant: 1 | 2 | 3 | 4 | 5;
  level?: 1 | 2 | 3 | 4 | 5;
  light?: boolean;
  thin?: boolean;
  children: ReactNode;
}

const Title: FC<TitleProps> = ({ className, variant, level, children, thin, light, alignment }) => {
  const TitleElement = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <TitleElement
      className={clsx(
        variant && `h${variant}`,
        thin && styles.thin,
        light && styles.light,
        alignment && styles[alignment],
        className
      )}
    >
      {children}
    </TitleElement>
  );
};

export { Title };
