import { FC } from 'react';
import { Outlet } from 'react-router';
import { useGetActivities, useGetRestDays, useGetSpecialEvents, useGetStages, useGetTeams } from '../../hooks';
import { Spinner } from '../spinner/Spinner';

const Root: FC = () => {
  const { isFetched: restDaysFetched } = useGetRestDays();
  const { isFetched: stagesFetched } = useGetStages();
  const { isFetched: specialEventsFetched } = useGetSpecialEvents();
  const { isFetched: teamsFetched } = useGetTeams();
  const { isFetched: allActivitiesFetched } = useGetActivities();

  if (restDaysFetched && stagesFetched && teamsFetched && allActivitiesFetched && specialEventsFetched) {
    return <Outlet />;
  }
  return <Spinner />;
};

export { Root };
