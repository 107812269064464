import { FC, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { StageNav } from '../../components';
import { useAppContext } from '../../hooks';

const Stages: FC = () => {
  const { stages } = useAppContext();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params.stage) {
      navigate('/stages/1');
    }
  }, [navigate, params.stage]);

  return (
    <>
      <StageNav stages={stages.tdf} currentDay={new Date()} />
      <Outlet />
    </>
  );
};

export { Stages };
