import clsx from 'clsx';
import { eachDayOfInterval, format, isSameDay, isWithinInterval } from 'date-fns';
import { FC } from 'react';
import { useMatch } from 'react-router';
import { useMediaQuery } from '../../../hooks';
import { Text } from '../../typography/text/Text';

import styles from './calendarEvent.module.css';

export interface CalendarEventProps {
  startDate: Date;
  endDate: Date;
  title: string;
  bulletList?: {
    title: string;
    items: { text: string; highlighted: boolean }[];
  };
  footerItems?: { date: Date; text: string }[];
  isRestDay?: boolean;
}

export const CalendarEvent: FC<CalendarEventProps> = (event) => {
  const match = useMatch('/');
  const isLargeScreen = useMediaQuery('(min-width: 1200px)');

  return (
    <div
      className={clsx(
        styles.event,
        event.isRestDay && styles.eventInactive,
        isWithinInterval(new Date(), {
          start: event.startDate,
          end: event.endDate ?? event.startDate
        }) && styles.eventCurrent,
        match && styles.eventHome
      )}
      style={{
        ...(!match &&
          isLargeScreen && {
            gridColumnStart: format(event.startDate, 'i'),
            gridColumnEnd: +format(event.endDate ?? event.startDate, 'i') + 1
          })
      }}
    >
      <div className={match ? styles.bodyHome : styles.body}>
        <div className={styles.days}>
          {eachDayOfInterval({
            start: event.startDate,
            end: event.endDate ?? event.startDate
          }).map((date) => (
            <Text
              key={date.toDateString()}
              className={clsx(styles.day, match && styles.dayHome, isSameDay(new Date(), date) && styles.dayCurrent)}
            >
              {format(date, 'dd')}
            </Text>
          ))}
        </div>
        <div>
          <p className={match ? styles.titleHome : styles.title} dangerouslySetInnerHTML={{ __html: event.title }} />
          {event.bulletList != null && (
            <div className={match ? '' : styles.bulletList}>
              <Text thin size="sm">
                {event.bulletList.title}
              </Text>
              {event.bulletList.items.map((item, index) => (
                <div key={index}>
                  <Text className={clsx(styles.bulletListItem, item.highlighted && styles.bulletListItemHighlighted)}>
                    {item.text}
                  </Text>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={clsx(styles.footer, match && styles.footerHome)}>
        {eachDayOfInterval({
          start: event.startDate,
          end: event.endDate ?? event.startDate
        }).map((date) => {
          const item = event.footerItems?.find((item) => isSameDay(item.date, date));
          return (
            <p
              key={date.toDateString()}
              className={styles.footerItem}
              dangerouslySetInnerHTML={{
                __html: item?.text ?? ''
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
