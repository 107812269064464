import clsx from 'clsx';
import { intervalToDuration } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../typography/text/Text';

import styles from './ranking.module.css';

export interface ListItemprops {
  id: string | number;
  index: number;
  name: string;
  totalMovingTime: number;
  totalDistance: number;
  highestMaxSpeed: number | string;
  highestAverageSpeed: number | string;
  targetAchieved: boolean;
  totalKudos: number;
  totalHeightMeters: number;
  speed: number;
  className?: string;
}

const ListItem: FC<ListItemprops> = ({
  id,
  index,
  name,
  totalMovingTime,
  totalDistance,
  totalHeightMeters,
  highestMaxSpeed,
  highestAverageSpeed,
  targetAchieved,
  totalKudos,
  speed,
  className
}) => {
  const { t } = useTranslation();

  const first = index === 0;
  const second = index === 1;
  const third = index === 2;

  return (
    <li
      key={id}
      className={clsx(
        className,

        !targetAchieved && styles.notAchieved,
        first && targetAchieved
          ? styles.first
          : second && targetAchieved
          ? styles.second
          : third && targetAchieved
          ? styles.third
          : ''
      )}
    >
      <Text thin={!targetAchieved} className={styles.listItemNumber}>
        {index + 1}
      </Text>
      <Text thin={!targetAchieved}>{name}</Text>
      <Text thin={!targetAchieved}>
        {totalMovingTime
          ? `${
              intervalToDuration({
                start: 0,
                end: totalMovingTime * 1000
              }).hours
            } ${t('UNITS.HOURS')} ${
              intervalToDuration({
                start: 0,
                end: totalMovingTime * 1000
              }).minutes
            } ${t('UNITS.MINUTES')}`
          : totalDistance
          ? `${(totalDistance / 1000).toFixed(2)} ${t('UNITS.KILOMETERS')}`
          : totalHeightMeters
          ? `${totalHeightMeters} ${t('UNITS.METERS')}`
          : highestMaxSpeed
          ? `${(Number(highestMaxSpeed) * 3.6).toFixed(2)} ${t('UNITS.KM/H')}`
          : highestAverageSpeed
          ? `${(Number(highestAverageSpeed) * 3.6).toFixed(2)} ${t('UNITS.KM/H')}`
          : totalKudos
          ? totalKudos
          : speed
          ? `${(speed * 3.6).toFixed(2)} ${t('UNITS.KM/H')}`
          : ''}
      </Text>
    </li>
  );
};

export { ListItem };
