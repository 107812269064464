import { FC } from 'react';
import { Outlet, useParams } from 'react-router';
import { StageNav, Text } from '../../components';
import { useAppContext } from '../../hooks';

const Specials: FC = () => {
  const { stages } = useAppContext();
  const params = useParams();
  return (
    <>
      {stages.specials.length > 0 ? (
        <>
          <StageNav stages={stages.specials} currentDay={new Date()} specials />
          {Object.keys(params).length === 0 && <Outlet />}
        </>
      ) : (
        <Text>No events created yet</Text>
      )}
    </>
  );
};

export { Specials };
