import { FC, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { useAppContext, useHover } from '../../hooks';
import { UserInfo } from '../userInfoWidget/UserInfo';
import { Text } from '../typography/text/Text';
import clsx from 'clsx';
import { TUser } from '../../types/types';

import styles from './login.module.css';

interface LoginProps {
  user: TUser;
}

const Login: FC<LoginProps> = ({ user }) => {
  const { logout, login } = useAppContext();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={styles.loginWrapper} onClick={() => setOpen(!open)}>
      <AiOutlineUser size={26} className={clsx(styles.logo, open && styles.logoActive)} />
      <div className={styles.statsWrapper}>
        {open &&
          (user ? (
            <UserInfo user={user} logout={logout} />
          ) : (
            <div onClick={() => login(window.location.pathname)}>
              <Text>Connect to Strava</Text>
            </div>
          ))}
      </div>
    </div>
  );
};

export { Login };
