import tdi1 from './tdi1.jpg';
import tdi2 from './tdi2.jpg';
import tdi3 from './tdi3.jpg';
import tdi4 from './tdi4.jpg';
import tdi5 from './tdi5.jpg';
import tdi6 from './tdi6.jpg';
import tdi7 from './tdi7.jpg';
import tdi8 from './tdi8.jpg';
import tdi9 from './tdi9.jpg';

const pictures = [tdi8, tdi9, tdi7, tdi1, tdi2, tdi3, tdi4, tdi5, tdi6];

export { pictures };
