import { FC } from 'react';
import stravaConnect from '../../assets/stravaConnect.svg';
import { useAppContext } from '../../hooks';

const StravaConnect: FC = () => {
  const { login } = useAppContext();
  return (
    <div style={{ cursor: 'pointer' }} onClick={() => login(window.location.pathname)}>
      <img src={stravaConnect} alt="stravaSVG" />
    </div>
  );
};

export { StravaConnect };
