import { FC } from 'react';
import { useCountdown } from '../../../hooks';
import { Title } from '../../typography/title/Title';
import { ShowCounter } from '../showCounter/ShowCounter';
import clsx from 'clsx';

import styles from '../countDown.module.css';

interface CountDownTimerProps {
  targetDate: Date;
  direction?: 'row' | 'column';
  title?: string;
  subtitle?: string;
  special?: boolean;
}

const CountdownTimer: FC<CountDownTimerProps> = ({ targetDate, direction, title, subtitle, special }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  return (
    <div className={clsx(styles.titleAndCount, special && styles.special)}>
      {title && (
        <Title variant={2} level={3}>
          {title}
        </Title>
      )}
      {subtitle && (
        <Title variant={3} level={4} thin>
          {subtitle}
        </Title>
      )}
      <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} direction={direction} />
    </div>
  );
};

export { CountdownTimer };
