import { REDUCER_ACTION_TYPE, TActivity } from '../types/types';

export const initUploadState: { activities: TActivity[] } = {
  activities: []
};

export type TReducerAction =
  | { type: REDUCER_ACTION_TYPE.TOGGLE_ACTIVITY_TO_UPLOAD; payload: TActivity }
  | { type: REDUCER_ACTION_TYPE.CLEAR_ACTIVITIES_TO_UPLOAD }
  | { type: REDUCER_ACTION_TYPE.ADD_ALL_ACTIVITIES_TO_UPLOAD; payload: TActivity[] };

export const uploadReducer = (state: { activities: TActivity[] }, action: TReducerAction) => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.TOGGLE_ACTIVITY_TO_UPLOAD:
      if (state.activities.includes(action.payload)) {
        return { ...state, activities: state.activities.filter((act) => act.id !== action.payload.id) };
      }
      return { ...state, activities: [...state.activities, action.payload] };

    case REDUCER_ACTION_TYPE.CLEAR_ACTIVITIES_TO_UPLOAD:
      return { ...state, activities: [] };

    case REDUCER_ACTION_TYPE.ADD_ALL_ACTIVITIES_TO_UPLOAD:
      return { ...state, activities: action.payload };
    default:
      return state;
  }
};
