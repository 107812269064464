import { TActivity, TTeam } from '../types/types';

/// FUNCTIONS TO CALCULATE THE RANKINGS ///

// Get the 3 fastest activities
const getFastest = (activities?: TActivity[]) =>
  activities?.sort((a, b) => b.distance / b.moving_time - a.distance / a.moving_time).slice(0, 3) ?? [];

// Get the 3 activities with the highest top speeds
const getHighestTopSpeed = (activities?: TActivity[]) =>
  activities?.sort((a, b) => b.max_speed - a.max_speed).slice(0, 3) ?? [];

// Get the 3 activities with the highest average speeds
const getHighestAverageSpeed = (activities?: TActivity[]) =>
  activities?.sort((a, b) => b.average_speed - a.average_speed).slice(0, 3) ?? [];

// get per team, the activities that are needed to reach the distance target
const getOrderedActivitiesPerTeamUntilKmTarget = (target: number, teams: TTeam[], activities?: TActivity[]) =>
  teams?.map((team) => {
    return {
      id: team.id,
      name: team.name,
      activities: getActivitiesUntilKmTarget(
        activities!.filter((activity) => activity.team_id === team.id),
        target
      ).activities
    };
  }) ?? [];

// get per team, the activities that are needed to reach the height target
const getOrderedActivitiesPerTeamUntilHmTarget = (target: number, teams: TTeam[], activities?: TActivity[]) =>
  teams?.map((team) => ({
    id: team.id,
    name: team.name,
    activities: getActivitiesUntilHmTarget(
      activities!.filter((activity) => activity.team_id === team.id),
      target
    ).activities
  })) ?? [];

// per team we calculate the distances of each activity and see if they have reached the target
const getAllOrderedActivitiesPerTeam = (teams: TTeam[], activities?: TActivity[], target?: number) => {
  const totalDistance = activities?.reduce((acc, curr) => acc + curr.distance, 0);
  return (
    teams?.map((t) => ({
      id: t.id,
      name: t.name,
      activities: activities!.filter((activity) => activity.team_id === t.id),
      targetAchieved: totalDistance! >= target! ?? false
    })) ?? []
  );
};

// we count the activities, (starting with first uploaded activity), until the distance target is reached
const getActivitiesUntilKmTarget = (activities: TActivity[], target: number) => {
  let sum = 0;
  let higher: TActivity[] = [];
  let lower: TActivity[] = [];
  activities.forEach((activity) => {
    sum = sum + activity.distance;
    if (sum < target) {
      lower.push(activity);
    }
    if (sum >= target) {
      higher.push(activity);
    }
  });
  return {
    target,
    activities: higher.length > 0 ? [...lower, higher[0]] : lower,
    targetAchieved: higher.length > 0 ?? false
  };
};

// we count the activities, (starting with first uploaded activity), until the height target is reached
const getActivitiesUntilHmTarget = (activities: TActivity[], target: number) => {
  let sum = 0;
  let higher: TActivity[] = [];
  let lower: TActivity[] = [];
  activities.forEach((activity) => {
    sum = sum + activity.total_elevation_gain;
    if (sum < target) {
      lower.push(activity);
    }
    if (sum >= target) {
      higher.push(activity);
    }
  });
  return {
    target,
    activities: higher.length > 0 ? [...lower, higher[0]] : lower,
    targetAchieved: higher.length > 0 ?? false
  };
};

// we calculate the total distance from all the activities a team has uploaded
const getTotalDistancePerTeamRanking = (
  teams: {
    id: number;
    name: string;
    activities: TActivity[];
  }[],
  target: number
) => {
  return (
    teams
      .map((team) => {
        const totalDistance = team.activities.reduce((acc, curr) => acc + curr.distance, 0);
        return {
          id: team.id,
          name: team.name,
          totalDistance,
          targetAchieved: totalDistance >= target ?? false
        };
      })
      .sort((a, b) => b.totalDistance - a.totalDistance)
      .slice(0, 3) ?? []
  );
};

// we calculate the total kudos from all the activities a team has uploaded
const getTotalKudosPerTeamRanking = (
  teams: {
    id: number;
    name: string;
    activities: TActivity[];
  }[]
) => {
  return (
    teams
      .map((team) => {
        const totalKudos = team.activities.reduce((acc, curr) => acc + curr.kudos_count, 0);

        return {
          id: team.id,
          name: team.name,
          totalKudos,
          targetAchieved: true
        };
      })
      .sort((a, b) => b.totalKudos - a.totalKudos)
      .slice(0, 3) ?? []
  );
};

// we calculate the total height meters that a team has collected with all their activities
const getTotalHeightMetersPerTeamRanking = (
  teams: {
    id: number;
    name: string;
    activities: TActivity[];
    targetAchieved?: boolean;
  }[],
  target: number
) =>
  teams
    .map((team) => {
      const totalHeightMeters = team.activities.reduce((acc, curr) => acc + curr.total_elevation_gain, 0);
      return {
        id: team.id,
        name: team.name,
        totalHeightMeters,
        targetAchieved: totalHeightMeters >= target ?? false
      };
    })
    .sort((a, b) => b.totalHeightMeters - a.totalHeightMeters)
    .slice(0, 3) ?? [];

// we calculate the total moving time over all the activities from a team
const getTotalMovingTimePerTeamRanking = (
  teams: {
    id: number;
    name: string;
    activities: TActivity[];
    targetAchieved?: boolean;
  }[],
  target: number
) => {
  return (
    teams
      .map((team) => {
        const totalDistance = team.activities.reduce((acc, curr) => acc + curr.distance, 0);
        return {
          id: team.id,
          name: team.name,
          totalMovingTime: team.activities.reduce((acc, curr) => acc + curr.moving_time, 0),
          targetAchieved: totalDistance >= target! ?? false,
          totalDistance
        };
      })
      .sort((a, b) => b.totalMovingTime - a.totalMovingTime)
      .slice(0, 3) ?? []
  );
};

const getHighestMaxSpeedRanking = (
  teams: {
    id: number;
    name: string;
    activities: TActivity[];
  }[],
  target?: number
) =>
  teams
    .map((team) => {
      const totalDistance = team.activities.reduce((acc, curr) => acc + curr.distance, 0);
      return {
        id: team.id,
        name: team.name,
        highestMaxSpeed: Math.max(...team.activities.map((activity) => activity.max_speed)),
        targetAchieved: totalDistance >= target! ?? false
      };
    })
    .sort((a, b) => b.highestMaxSpeed - a.highestMaxSpeed)
    .map((team) => ({ ...team, highestMaxSpeed: team.highestMaxSpeed.toFixed(2) }))
    .slice(0, 3) ?? [];

const getHighestAverageSpeedRanking = (
  teams: {
    id: number;
    name: string;
    activities: TActivity[];
  }[],
  target?: number
) => {
  return (
    teams
      .map((team) => {
        const totalDistance = team.activities.reduce((acc, curr) => acc + curr.distance, 0);
        return {
          id: team.id,
          name: team.name,
          highestAverageSpeed: Math.max(...team.activities.map((activity) => activity.average_speed)),
          targetAchieved: totalDistance >= target! ?? false
        };
      })
      .sort((a, b) => b.highestAverageSpeed - a.highestAverageSpeed)
      .map((team) => ({ ...team, highestAverageSpeed: team.highestAverageSpeed.toFixed(2) }))
      .slice(0, 3) ?? []
  );
};

export {
  getFastest,
  getHighestTopSpeed,
  getHighestAverageSpeed,
  getOrderedActivitiesPerTeamUntilKmTarget,
  getOrderedActivitiesPerTeamUntilHmTarget,
  getAllOrderedActivitiesPerTeam,
  getActivitiesUntilKmTarget,
  getActivitiesUntilHmTarget,
  getTotalDistancePerTeamRanking,
  getTotalKudosPerTeamRanking,
  getTotalHeightMetersPerTeamRanking,
  getTotalMovingTimePerTeamRanking,
  getHighestMaxSpeedRanking,
  getHighestAverageSpeedRanking
};
