import { FC, useState } from 'react';
import { useLocation, useMatch } from 'react-router';
import { headerTitleFormatter } from '../../utils/helpers';
import icappsLogo from '../../assets/icapps_white.png';
import { Button } from '../button/Button';
import clsx from 'clsx';
import { Title } from '../typography/title/Title';
import { Text } from '../typography/text/Text';
import { useAppContext, useMediaQuery, useScrollDirection } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { IoHomeSharp, IoArrowUpSharp } from 'react-icons/io5';
import { Login } from '../login/Login';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';

import styles from './header.module.css';

const Header: FC = () => {
  const { user } = useAppContext();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const scrollDirection = useScrollDirection();
  const home = useMatch('/');
  const upload = useMatch('/uploadzone');
  const smallScreen = useMediaQuery('(max-width: 1000px)');
  const { scrollY } = useScroll();
  const [hide, setHide] = useState<boolean>(false);

  useMotionValueEvent(scrollY, 'change', (latest) => (latest >= 100 ? setHide(true) : setHide(false)));

  return (
    <motion.div
      className={styles.headerWrapper}
      variants={{
        visible: { opacity: 1, y: 1 },
        hidden: { opacity: 0, y: '-100%' }
      }}
      animate={hide ? 'hidden' : 'visible'}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.5 }}
    >
      <div className={clsx(styles.header, scrollDirection === 'down' && !home && styles.scrollDown)}>
        <Title variant={2} level={1} className={styles.title}>
          <div className={styles.titleFirst}>
            {!home && (
              <Text size="lg" uppercase>
                TOUR D'
              </Text>
            )}
            <img src={icappsLogo} alt="icappslogo" height={20} />
          </div>
          <Text size="lg">{headerTitleFormatter(pathname)}</Text>
        </Title>
        <nav className={styles.headerBtnGroup}>
          <>
            {!smallScreen && (
              <Button to="specials" size="small" variant={home ? 'action' : 'standard'} className="specialEventBtn">
                <Text>{t('SPECIAL_EVENTS.NAME')}</Text>
              </Button>
            )}

            {!upload && (
              <Button to="uploadzone" size="small" variant="action" mobile={smallScreen}>
                {smallScreen ? <IoArrowUpSharp size={20} /> : <Text>{t('UPLOAD.NAME')} </Text>}
              </Button>
            )}
          </>

          {!home && (
            <Button to="/" size="small" variant="standard" mobile={smallScreen}>
              {smallScreen ? <IoHomeSharp size={20} /> : <Text>Home</Text>}
            </Button>
          )}
          <Login user={user} />
        </nav>
      </div>
    </motion.div>
  );
};

export { Header };
