import { FC } from 'react';
import { Calendar } from '../../components';
import { CalendarEventProps } from '../../components/calendar/calendarEvent/CalendarEvent';
import { useAppContext } from '../../hooks';
import { TRestDay, TStage, TtdfStage } from '../../types/types';
import i18next from 'i18next';

export const Challenges: FC = () => {
  const { stages, restDays } = useAppContext();
  return <Calendar events={constructUpcomingEvents(stages.tdf, restDays)} />;
};

export const constructUpcomingEvents = (stages: TStage[], restDays: TRestDay[]): CalendarEventProps[] => {
  const mapToStageEvents = () => {
    return (
      stages
        ?.filter((stage) => stage.start_date != null)
        ?.map((stage) => ({
          ...mapToEventBody(stage),
          footerItems: stage.tdf_stages?.map((stage: TtdfStage) => ({
            date: stage.date,
            text: `${stage.start} ><br>${stage.arrival} ${stage.distance / 1000} km`
          }))
        })) ?? []
    );
  };

  const mapToRestDayEvents = () => {
    return (
      restDays?.map((date) => ({
        startDate: date.start_date,
        endDate: date.end_date,
        title: i18next.language === 'nl' ? 'Rustdag' : 'Rest day',
        isRestDay: true
      })) ?? []
    );
  };

  const mapToEventBody = (data: {
    start_date: Date;
    name: { nl: string; en: string };
    challenges: { nl: string[]; en: string[] };
    end_date: Date;
  }) => {
    if (i18next.language === 'nl') {
      return {
        startDate: data.start_date,
        endDate: data.end_date,
        title: data.name.nl,
        bulletList: {
          title: data.challenges.nl[0] === '' ? 'Veel plezier!' : 'Opdrachten',
          items: data.challenges.nl.map((challenge) => ({
            text: challenge,
            highlighted: false
          }))
        }
      };
    }
    if (i18next.language === 'en') {
    }
    return {
      startDate: data.start_date,
      endDate: data.end_date,
      title: data.name.en,
      bulletList: {
        title: data.challenges.en[0] === '' ? 'Have a nice time!' : 'Challenges',
        items: data.challenges.en.map((challenge) => ({
          text: challenge,
          highlighted: false
        }))
      }
    };
  };

  const sortedEvents = [...mapToStageEvents(), ...mapToRestDayEvents()].slice();
  sortedEvents.sort((event, event2) => event.startDate.getTime() - event2.startDate.getTime());

  return sortedEvents;
};
