import { useCallback, useEffect, useState } from 'react';

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  const handleMediaQueryListEvent = useCallback(
    (changedEvent: MediaQueryListEvent) => setMatches(changedEvent.matches),
    []
  );

  useEffect(() => {
    window.matchMedia(query).addEventListener('change', handleMediaQueryListEvent);

    return () => {
      window.removeEventListener('change', handleMediaQueryListEvent as any);
    };
  }, [handleMediaQueryListEvent, query]);

  return matches;
};
