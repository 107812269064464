import { useTranslation } from 'react-i18next';
import { TTeam, TActivity } from '../types/types';
import {
  getOrderedActivitiesPerTeamUntilKmTarget,
  getTotalMovingTimePerTeamRanking,
  getTotalHeightMetersPerTeamRanking,
  getAllOrderedActivitiesPerTeam,
  getTotalDistancePerTeamRanking,
  getHighestMaxSpeedRanking,
  getHighestAverageSpeedRanking,
  getOrderedActivitiesPerTeamUntilHmTarget,
  getFastest,
  shortenName,
  getHighestTopSpeed,
  getTotalKudosPerTeamRanking,
  getHighestAverageSpeed
} from '../utils';

const useCalculations = () => {
  const { t } = useTranslation();

  const stage1 = (teams: TTeam[], activities: TActivity[]) => {
    const target = 250000;
    const activitiesUntiesUntilTargetPerTeam = getOrderedActivitiesPerTeamUntilKmTarget(target, teams, activities);

    return [
      {
        title: `${t('RANKINGS.TITLE.FIRST_TO')} ${target / 1000} ${t('UNITS.KILOMETERS')}`,
        ranking: getTotalMovingTimePerTeamRanking(activitiesUntiesUntilTargetPerTeam, target)
          .reverse()
          .slice(0, 3)
          .sort((a, b) => Number(b.targetAchieved) - Number(a.targetAchieved))
      },
      {
        title: t('RANKINGS.TITLE.MOST_HEIGHT_METERS'),
        ranking: getTotalHeightMetersPerTeamRanking(activitiesUntiesUntilTargetPerTeam, target)
          .slice(0, 3)
          .sort((a, b) => Number(b.targetAchieved) - Number(a.targetAchieved))
      }
    ];
  };

  const stage2or5 = (teams: TTeam[], activities: TActivity[]) => {
    const minimumDistance = 100000;
    const activitiesPerTeam = getAllOrderedActivitiesPerTeam(teams, activities, minimumDistance);

    return [
      {
        title: t('RANKINGS.TITLE.MOST_KILOMETERS'),
        ranking: getTotalDistancePerTeamRanking(activitiesPerTeam, minimumDistance).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      },
      {
        title: t('RANKINGS.TITLE.MOST_HEIGHT_METERS'),
        ranking: getTotalHeightMetersPerTeamRanking(activitiesPerTeam, minimumDistance).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      },
      {
        title: t('RANKINGS.TITLE.MOST_MOVING_TIME'),
        ranking: getTotalMovingTimePerTeamRanking(activitiesPerTeam, minimumDistance).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      },
      {
        title: t('RANKINGS.TITLE.HIGHEST_MAX_SPEED'),
        ranking: getHighestMaxSpeedRanking(activitiesPerTeam, minimumDistance).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      },
      {
        title: t('RANKINGS.TITLE.HIGHEST_AVG_SPEED'),
        ranking: getHighestAverageSpeedRanking(activitiesPerTeam, minimumDistance).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      }
    ];
  };

  const stage3 = (teams: TTeam[], activities: TActivity[]) => {
    const distanceTarget = 250000;
    const heightTarget = 500;
    const activitiesUntilDistanceTargetPerTeam = getOrderedActivitiesPerTeamUntilKmTarget(
      distanceTarget,
      teams,
      activities
    );
    const firstToDistanceTargetRanking = getTotalMovingTimePerTeamRanking(
      activitiesUntilDistanceTargetPerTeam,
      distanceTarget
    ).reverse();

    const activitiesUntilHeigtTargetPerTeam = getOrderedActivitiesPerTeamUntilHmTarget(heightTarget, teams, activities);

    const firstToHeightTargetRanking = getTotalHeightMetersPerTeamRanking(
      activitiesUntilHeigtTargetPerTeam,
      heightTarget
    );

    return [
      {
        title: `${t('RANKINGS.TITLE.FIRST_TO')} ${distanceTarget / 1000} ${t('UNITS.KILOMETERS')}`,
        ranking: firstToDistanceTargetRanking.sort((a, b) => Number(b.targetAchieved) - Number(a.targetAchieved))
      },
      {
        title: `${t('RANKINGS.TITLE.FIRST_TO')} ${heightTarget} ${t('UNITS.HEIGHT_METERS')}`,
        ranking: firstToHeightTargetRanking.sort((a, b) => Number(b.targetAchieved) - Number(a.targetAchieved))
      },
      {
        title: t('RANKINGS.TITLE.MOST_HEIGHT_METERS'),
        ranking: getTotalHeightMetersPerTeamRanking(activitiesUntilDistanceTargetPerTeam, heightTarget).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      }
    ];
  };

  // STAGE 4: 7/7 - 9/7 (INDIVIDUAL) (for now out of scope)
  // - at least 25000 m to compete
  // - best strava picture
  // - most beautiful outfit

  // STAGE 5 -> OK (SAME AS STAGE 2)

  const stage6 = (activities: TActivity[]) => {
    const target = 22000;
    return [
      {
        title: t('RANKINGS.TITLE.FASTEST_ATHLETE'),
        ranking: getFastest(activities)
          .map((activity) => ({
            id: activity.athlete.id,
            name: shortenName(activity.athlete_name!),
            speed: activity.distance / activity.moving_time!,
            targetAchieved: activity.distance >= target
          }))
          .sort((a, b) => Number(b.targetAchieved) - Number(a.targetAchieved))
      },
      {
        title: t('RANKINGS.TITLE.HIGHEST_TOP_SPEED_ATHLETE'),
        ranking: getHighestTopSpeed(activities)
          .map((activity) => ({
            id: activity.athlete.id,
            name: shortenName(activity.athlete_name!),
            totalMaxSpeed: activity.max_speed!.toFixed(2),
            targetAchieved: activity.distance >= target
          }))
          .sort((a, b) => Number(b.targetAchieved) - Number(a.targetAchieved))
      }
    ];
  };

  // STAGE 7: 19/7 (TEAM - INDIVIDUAL) (for now out of scope)
  // - make most beautiful strava art (individual)

  const stage7 = (teams: TTeam[], activities: TActivity[]) => {
    const activitiesPerTeam = getAllOrderedActivitiesPerTeam(teams, activities);
    return [
      {
        title: t('RANKINGS.TITLE.MOST_KUDOS'),
        ranking: getTotalKudosPerTeamRanking(activitiesPerTeam)
      }
    ];
  };

  const stage8 = (teams: TTeam[], activities: TActivity[]) => {
    const minimumDistance = 150000;
    const activitiesPerTeam = getAllOrderedActivitiesPerTeam(teams, activities);
    return [
      {
        title: t('RANKINGS.TITLE.MOST_KILOMETERS'),
        ranking: getTotalDistancePerTeamRanking(activitiesPerTeam, minimumDistance).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      },
      {
        title: t('RANKINGS.TITLE.MOST_HEIGHT_METERS'),
        ranking: getTotalHeightMetersPerTeamRanking(activitiesPerTeam, minimumDistance).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      },
      {
        title: t('RANKINGS.TITLE.MOST_MOVING_TIME'),
        ranking: getTotalMovingTimePerTeamRanking(activitiesPerTeam, minimumDistance).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      },
      {
        title: t('RANKINGS.TITLE.HIGHEST_MAX_SPEED'),
        ranking: getHighestMaxSpeedRanking(activitiesPerTeam, minimumDistance).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      },
      {
        title: t('RANKINGS.TITLE.HIGHEST_AVG_SPEED'),
        ranking: getHighestAverageSpeedRanking(activitiesPerTeam, minimumDistance).sort(
          (a, b) => Number(b.targetAchieved) - Number(a.targetAchieved)
        )
      }
    ];
  };

  // STAGE 9: 23/7 NO CALCULATIONS NEEDED

  // STAGE 10 AKA SPECIALSTAGE:
  const specialStage = (activities: TActivity[]) => {
    const target = 50000;
    const heightTarget = 1000;

    return [
      {
        title: t('RANKINGS.TITLE.HIGHEST_TOP_SPEED_ATHLETE'),
        ranking: getHighestTopSpeed(activities)
          .map((activity) => {
            return {
              id: activity.athlete.id,
              name: shortenName(activity.athlete_name!),
              highestMaxSpeed: activity.max_speed!.toFixed(2),
              targetAchieved: activity.distance >= target && activity.total_elevation_gain >= heightTarget
            };
          })
          .sort((a, b) => Number(b.targetAchieved) - Number(a.targetAchieved))
      },
      {
        title: t('RANKINGS.TITLE.HIGHEST_AVG_SPEED_ATHLETE'),
        ranking: getHighestAverageSpeed(activities)
          .map((activity) => ({
            id: activity.athlete.id,
            name: shortenName(activity.athlete_name!),
            highestAverageSpeed: activity.average_speed!.toFixed(2),
            targetAchieved: activity.distance >= target && activity.total_elevation_gain >= heightTarget
          }))
          .sort((a, b) => Number(b.targetAchieved) - Number(a.targetAchieved))
      },
      {
        title: t('RANKINGS.TITLE.FASTEST_ATHLETE'),
        ranking: getFastest(activities)
          .map((activity) => ({
            id: activity.athlete.id,
            name: shortenName(activity.athlete_name!),
            speed: activity.distance / activity.moving_time!,
            targetAchieved: activity.distance >= target && activity.total_elevation_gain >= heightTarget
          }))
          .sort((a, b) => Number(b.targetAchieved) - Number(a.targetAchieved))
      }
    ];
  };

  return { stage1, stage2or5, stage3, stage6, stage7, stage8, specialStage };
};

export { useCalculations };
