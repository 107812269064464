export type TUser =
  | {
      access_token: string;
      expires_at: number;
      expires_in: number;
      refresh_token: string;
      token_type: 'Bearer';
      athlete: TAthlete;
      activities?: TActivity[];
      team?: TTeam;
    }
  | undefined;

export type TAthlete = {
  id?: number;
  username?: string;
  firstname?: string;
  lastname?: string;
};

export type TActivity = {
  id: number;
  athlete: { id: number };
  name: string;
  moving_time: number; // seconds
  total_elevation_gain: number; // meters
  average_speed: number; // meters per second (+/- 7 = 25km/h)
  max_speed: number; // meters per second (+/- 15 = 54km/h)
  distance: number; // meters
  start_date_local: string;
  athlete_name?: string; //eg: mathias scheltjens
  checked?: boolean; // only in frontend, not in api
  disabled?: boolean;
  kudos_count: number;
  map: TMap;
  sport_type: 'Ride';
  team_id?: number;
  stage_id?: string;
};

export type TMap = {
  id: string;
  summary_polyline: string;
};

export type TtdfStage = {
  start: string;
  arrival: string;
  date: Date;
  distance: number;
};

export type TStage = {
  id: string;
  challenges: { nl: string[]; en: string[] };
  distance: number;
  name: { nl: string; en: string };
  start_date: Date;
  end_date: Date;
  tdf_stages?: TtdfStage[];
  target_height?: number;
  special_stage?: boolean;
};

export type TNonStage = {
  challenges: string[];
  name: string;
  start_date: Date;
};

export type TRestDay = {
  start_date: Date;
  end_date: Date;
};

export type TTeam = {
  name: string;
  id: number;
  members: { id: number; username: string }[];
};

export type TtokenData =
  | {
      access_token: string;
      expires_at: number;
      expires_in: number;
      refresh_token: string;
    }
  | undefined;

export type TInitState = {
  stravaUser: TUser;
  teams: TTeam[];
  stages: TStage[];
  restDays: TRestDay[];
  activities: TActivity[];
  specialEvents: TStage[];
  currentStage: TStage | undefined;
};

export const enum REDUCER_ACTION_TYPE {
  SET_USER_DATA,
  SET_RESTDAYS,
  SET_TEAMS,
  SET_STAGES,
  SET_ACTIVITIES,
  ADD_ACTIVITIES,
  SET_SPECIAL_EVENTS,
  TOGGLE_ACTIVITY_TO_UPLOAD,
  CLEAR_ACTIVITIES_TO_UPLOAD,
  ADD_ALL_ACTIVITIES_TO_UPLOAD
}

export type TUseAppDataHook = {
  user: TUser | undefined;
  activities: TActivity[];
  teams: TTeam[];
  stages: { tdf: TStage[]; specials: TStage[] };
  restDays: TRestDay[];
  currentStage: TStage | undefined;
  setActivities: (activities: TActivity[]) => void;
  setStages: (stages: TStage[]) => void;
  setSpecialEvents: (specialEvents: TStage[]) => void;
  setUser: (user: TUser) => void;
  setTeams: (teams: TTeam[]) => void;
  setRestDays: (restDays: TRestDay[]) => void;
  addActivities: (activities: TActivity[]) => void;
  logout: () => void;
  login: (redirectPath: string) => void;
};

// addAllActivitiesToUpload: (activities: TActivity[]) => void;
// clearActivitiesToUpload: () => void;
// toggleActivityToUpload: (activity: TActivity) => void;
